import { CollectibleState } from "@manifoldxyz/collectible-sdk";
import { CollectibleSDK } from "@manifoldxyz/collectible-sdk/dist/CollectibleSDK";
import { setProviderEventsHandler } from "@manifoldxyz/manifold-dropsitetools-lib";
import { EthereumNetwork, EthereumProvider } from "@manifoldxyz/manifold-sdk";
import { useStoreAtAddress } from "./storeDefinition";

// Create a new store instance.
const createStore = (
  collectibleAddress: string,
  collectibleSDK: CollectibleSDK,
  network: EthereumNetwork,
  fallbackProvider: string,
  crossmintClientId: string
) => {
  const store = useStoreAtAddress(collectibleAddress);

  store.setCollectibleAddress(collectibleAddress);
  store.setCrossmintClientId(crossmintClientId);

  const onCollectibleStateDidUpdate = (newCollectibleState: CollectibleState) => {
    store.setCollectibleState(newCollectibleState, collectibleSDK);
  };

  const initializeCollectibleSDK = () => {
    collectibleSDK.subscribe(onCollectibleStateDidUpdate);
  };

  const initializeProvider = async () => {
    const provider = EthereumProvider.provider();

    if (!provider) {
      await EthereumProvider.initialize(network, fallbackProvider);
    }

    store.setProviderAvailable(Boolean(EthereumProvider.provider()));
  };

  const onProviderEvent = async () => {
    const provider = EthereumProvider.provider();
    const address = EthereumProvider.selectedAddress();

    store.setWalletAddress(EthereumProvider.selectedAddress()!);
    store.setProviderAvailable(Boolean(provider));

    if (provider && address) {
      store.setWalletBalance(await provider.getBalance(address));
    } else {
      store.setWalletBalance(undefined);
    }
  };

  setProviderEventsHandler(onProviderEvent);
  initializeCollectibleSDK();
  initializeProvider();

  return store;
};

declare global {
  interface Window {
    __ManifoldCollectibleStoreMap: {
      [collectibleAddress: string]: ReturnType<typeof createStore>;
    };
  }
}

if (!window.__ManifoldCollectibleStoreMap) {
  window.__ManifoldCollectibleStoreMap = {};
}

export const createStoreSingleton = (
  collectibleAddress: string,
  collectibleSDK: CollectibleSDK,
  network: EthereumNetwork,
  fallbackProvider: string,
  crossmintClientId: string
) => {
  if (window.__ManifoldCollectibleStoreMap[collectibleAddress]) {
    return window.__ManifoldCollectibleStoreMap[collectibleAddress];
  }

  const store = createStore(
    collectibleAddress,
    collectibleSDK,
    network,
    fallbackProvider,
    crossmintClientId
  );
  setCurrentTimeInterval(store);
  window.__ManifoldCollectibleStoreMap[collectibleAddress] = store;

  return store;
};

const setCurrentTime = (store: ReturnType<typeof createStore>) => {
  store.setNow(Date.now());
};

const setCurrentTimeInterval = (store: ReturnType<typeof createStore>) => {
  setTimeout(() => {
    setCurrentTime(store);
    requestAnimationFrame(() => setCurrentTimeInterval(store));
  }, 1000);
};
