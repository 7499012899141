import { ethers } from "ethers";

export const Unveil = "0x7a607f1ab973d85727d0ff44300699ee672c0e1d76f35013f36c02772f524ba3";
export const unveilInterface = new ethers.utils.Interface([
  "event Unveil(uint256 collectibleId, address tokenAddress, uint256 tokenId)",
]);

export const CollectionActivated =
  "0xe512d106f8172b08abfd2fc5ddfdb7e2401381780ae29e29f1352b862f1d2731";

export const CollectionActivatedInterface = new ethers.utils.Interface([
  "event CollectionActivated(uint256 startTime, uint256 endTime, uint256 presaleInterval, uint256 claimStartTime, uint256 claimEndTime)",
]);

export const CollectionDeactivated =
  "0xb02389feab3af620e2374d4d559b436ea226b1e6c9c31fe77dfbff3d40cbe9ba";

export const MODE = process.env.VUE_APP_MODE;

export const TransferSingle = "0xc3d58168c5ae7397731d063d5bbf3d657854427343f4c083240f7aacaa2d0f62";
export const transferSingleInterface = new ethers.utils.Interface([
  "event TransferSingle(address indexed operator, address indexed from, address indexed to, uint256 id, uint256 value)",
]);

export const COLLECTIBLE_BACKEND_MAP: { [key: number]: string } = {
  1: "https://collectible.api.manifoldxyz.dev",
  4: "https://goerli.collectible.api.manifoldxyz.dev",
  5: "https://goerli.collectible.api.manifoldxyz.dev",
  137: "https://matic.collectible.api.manifoldxyz.dev",
};

export const CROSSMINT_ADDRESS = "0xdAb1a1854214684acE522439684a145E62505233";

export const CROSSMINT_ENVIRONMENT_MAP: { [key: number]: string } = {
  1: "prod",
  5: "staging",
};
