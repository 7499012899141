import manifoldCSSReset from "@manifoldxyz/css-reset";
import {
  Buy,
  ClaimRefund,
  Collectible,
  Countdown,
  CurrentPrice,
  DutchIntervalCountdown,
  Inventory,
} from "@/components";
import CollectibleView from "@/widgets/CollectibleView.vue";
import { renderComponentWithApp } from "./mount";

manifoldCSSReset();

export interface ManifoldWindow extends Window {
  mStore: any;
  analytics: any;
}

/**
 * Begin: Installs Google Recaptch V2
 */

let elLoadCallback = document.createElement("script");
elLoadCallback.innerHTML = "var onloadCallback = function() { window.recaptchaReady = true; };";
document.head.appendChild(elLoadCallback);

const elRecaptchaScript = document.createElement("script");
elRecaptchaScript.setAttribute(
  "src",
  "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
);
elRecaptchaScript.setAttribute("async", "true");
elRecaptchaScript.setAttribute("defer", "true");
document.head.appendChild(elRecaptchaScript);

/**
 * End: Installs Google Recaptch V2
 */

/**
 * Begin: Installs Crossmint Components
 */

elLoadCallback = document.createElement("script");
elLoadCallback.innerHTML = "var onloadCallback = function() { window.recaptchaReady = true; };";
document.head.appendChild(elLoadCallback);

const elCrossmintScript = document.createElement("script");
elCrossmintScript.setAttribute(
  "src",
  "https://unpkg.com/@crossmint/client-sdk-vanilla-ui@0.1.0/lib/index.global.js"
);
document.head.appendChild(elCrossmintScript);

/**
 * End: Installs Crossmint Components
 */

// Vue.config.productionTip = false;
let mInterval: number | boolean | null;

if (window) {
  const renderedElements = new Map();
  const instantiate = () => {
    let timeout = 10;
    if (!mInterval) {
      mInterval = window.setInterval(() => {
        timeout--;

        const widget: { [key: string]: any } = {
          view: CollectibleView,
          buy: Buy,
          collectible: Collectible,
          countdown: Countdown,
          currentPrice: CurrentPrice,
          dutchIntervalCountdown: DutchIntervalCountdown,
          inventory: Inventory,
          claimRefund: ClaimRefund,
        };
        const elements = [
          ...[...document.querySelectorAll("[data-widget='m-view']")].map((e) => {
            return {
              el: e,
              type: "view",
            };
          }),
          ...[...document.querySelectorAll("[data-widget='m-buy']")].map((e) => {
            return {
              el: e,
              type: "buy",
            };
          }),
          ...[...document.querySelectorAll("[data-widget='m-collectible']")].map((e) => {
            return {
              el: e,
              type: "collectible",
            };
          }),
          ...[...document.querySelectorAll("[data-widget='m-countdown']")].map((e) => {
            return {
              el: e,
              type: "countdown",
            };
          }),
          ...[...document.querySelectorAll("[data-widget='m-current-price']")].map((e) => {
            return {
              el: e,
              type: "currentPrice",
            };
          }),
          ...[...document.querySelectorAll("[data-widget='m-dutch-interval-countdown']")].map(
            (e) => {
              return {
                el: e,
                type: "dutchIntervalCountdown",
              };
            }
          ),
          ...[...document.querySelectorAll("[data-widget='m-inventory']")].map((e) => {
            return {
              el: e,
              type: "inventory",
            };
          }),
          ...[...document.querySelectorAll("[data-widget='m-claim-refund']")].map((e) => {
            return {
              el: e,
              type: "claimRefund",
            };
          }),
        ];

        if (elements.length || timeout === 0) {
          clearInterval(mInterval as number);

          if (timeout === 0) {
            console.warn("Collectible widget mount timed out.");
          }

          elements.forEach(({ el, type }) => {
            if (!renderedElements.get(el)) {
              const network = parseInt((el as HTMLElement).dataset.network || "");

              if (Number.isNaN(network)) {
                throw new Error("invalid network");
              }

              // Typecasting boolean strings as boolean
              const _propsData = (el as HTMLElement).dataset;
              const requireAdditionalPricingData = Boolean(_propsData.requireAdditionalPricingData);
              delete _propsData.requireAdditionalPricingData;
              const propsData: any = {
                ..._propsData,
                requireAdditionalPricingData: requireAdditionalPricingData,
              };

              const vueEl = renderComponentWithApp({
                el: el,
                component: widget[type],
                props: {
                  ...propsData,
                  network,
                },
                collectibleAddress: propsData.address,
              });

              renderedElements.set(el, vueEl);
            }
          });
        }
      }, 1000);
    }
  };
  const removeWidgetsNoLongerInDOM = () => {
    renderedElements.forEach((vueElement, element) => {
      if (!document.body.contains(element)) {
        vueElement.$destroy();
      }
      renderedElements.delete(element);
    });
  };

  /**
   * Consuming app should dispatch the `m-refresh-widgets` event whenever we need to resync
   * with the DOM. This should particularly occur after page changes.
   */
  window.addEventListener("m-refresh-widgets", () => {
    clearInterval(mInterval as number);
    mInterval = null;
    removeWidgetsNoLongerInDOM();
    instantiate();
  });
}

function prepareExportable(Component: any, componentTag: string) {
  return {
    install(Vue: any) {
      clearInterval(mInterval as number);
      mInterval = true;
      Vue.component(componentTag, Component);
    },
  };
}

export default {
  CollectibleView: prepareExportable(CollectibleView, "m-view"),
  Buy: prepareExportable(Buy, "m-buy"),
  Collectible: prepareExportable(Collectible, "m-collectible"),
  Countdown: prepareExportable(Countdown, "m-countdown"),
  CurrentPrice: prepareExportable(CurrentPrice, "m-current-price"),
  DutchIntervalCountdown: prepareExportable(DutchIntervalCountdown, "m-dutch-interval-countdown"),
  Inventory: prepareExportable(Inventory, "m-inventory"),
  ClaimRefund: prepareExportable(ClaimRefund, "m-claim-refund"),
};
