import { inject } from "vue";
import { ContractType } from "@manifoldxyz/collectible-sdk";
import { EthereumNetwork } from "@manifoldxyz/manifold-sdk";
import type { Messaging } from "./useMessaging";

type BaseProps = {
  address: string;
  network: EthereumNetwork;
  fallbackProvider: string;
  contractType: ContractType;
  appName: string;
  clientId: string;
  requireAdditionalPricingData?: boolean;
  messaging?: Partial<typeof Messaging>;
};

export const useBaseProps = (): BaseProps => {
  const {
    address,
    network,
    fallbackProvider,
    contractType,
    appName,
    clientId,
    requireAdditionalPricingData,
    messaging,
  } = inject("baseProps") as BaseProps;

  return {
    address,
    network,
    fallbackProvider,
    contractType,
    appName,
    clientId,
    requireAdditionalPricingData,
    // `messaging` is a JSON string.
    messaging: !messaging ? null : JSON.parse(messaging as unknown as string),
  };
};
